import { Box, Stack, Typography } from "@mui/material";
import { Activity } from "../activity/components/Activity";
import HcIcon from "../../assets/hc-icon.png";
import TextImg from "../../assets/text-img.png";
import { StyledItemType } from "./components/StyledItem";
import ChristmasWreath from "../../assets/christmas-wreath.svg";

export interface ActivityType extends StyledItemType {
  title: string;
  // location: string;
  // participants: string;
  // isHot: boolean;
  // isRecommended: boolean;
  // externalLink: string;
}

const linkPrefix = `
  https://api.whatsapp.com/send?phone=+85244476749&text=Hello 小角樂Happy Corner%F0%9F%98%83，我想報名參加`;

const activities: ActivityType[] = [
  // {
  //   title: "【白色情人節🤍】🍫朱古力布朗尼交友班🍫",
  // },
  // {
  //   title: "✨💃🏼𝟑𝟎人MBTI交友Party [28/9]🕺🏻✨",
  // },
  {
    title: "🧪𝐇𝐚𝐥𝐥𝐨𝐰𝐞𝐞𝐧 𝐏𝐚𝐫𝐭𝐲👻[26/10晚]",
  },
  {
    title: "🗝️密室逃脫交友活動🗝️",
  },
  {
    title: "🍋火焰檸檬批班🍋",
  },
  {
    title: "🪐 星球香芋酥班 🪐 ",
  },
  {
    title: "🥧 法式蘋果撻班 🥧",
  },
  {
    title: "🥯抹茶麻薯鬆餅班🥯",
  },
  // {
  //   title: "🥧 法式蘋果撻製作班 🥧",
  // },
  // {
  //   title: "[交友Party🔥27/1‼️]  🎏 冬日和風交友Party ❄️",
  // },
  // {
  //   preTitle: "[❄️12月限定]",
  //   title: "🎄 松果聖誕樹製作班 🎄",
  // },
  // {
  //   preTitle: "[❄️12月限定]",
  //   title: "聖誕松花圈製作班",
  //   activityTitleImg: ChristmasWreath,
  // },
  // {
  //   preTitle: "[❄️12月限定]",
  //   title: "🍪 聖誕曲奇製作班 🍪",
  // },
  // {
  //   title: "🌿斑蘭椰汁千層糕班🌿",
  // },
  {
    title: "🍶 單身陶藝拉坯班 🍶",
  },
  {
    title: "🍓 日式大福和菓子製作班 🍓",
  },
  {
    title: "☕️ 單身咖啡拉花班 ☕️",
  },
  {
    title: "🪴 水磨石空氣草盆栽班 🪴",
  },
  {
    title: "🪔 香氛沙畫蠟燭班 🪔",
  },

  // {
  //   title: "🎄 松果聖誕樹手作班 🎄",
  //   externalLink: "https://forms.gle/977UbbVnV3gJ4goD6",
  // },
  {
    title: "🔹 Board game交友活動 🔹",
    location: "旺角",
    participants: "4男4女",
    isHot: false,
    isRecommended: false,
    // externalLink: "https://forms.gle/G3nCHyMTyVaoXznq5",
  },
  // {
  //   title: "🔶 DIY馬賽克杯墊班 🔶",
  //   location: "荔枝角",
  //   participants: "4男4女",
  //   isHot: false,
  //   isRecommended: false,
  // },
  // {
  //   title: "⛰️ 行山交友活動 ⛰️",
  //   location: "九龍",
  //   participants: "4男4女",
  //   isHot: false,
  //   isRecommended: false,
  //   // externalLink: "https://forms.gle/qvJMPGaoTUR2x4RM6",
  // },
  {
    title: "🚣🏼‍♂️ 獨木舟交友活動 🚣🏼‍♂️",
    location: "西貢",
    participants: "4男4女",
    isHot: false,
    isRecommended: false,
    // externalLink: "https://forms.gle/Zj2L9Jd3V5JfyKot6",
  },
  {
    title: "🏕️露營交友 2日1夜🏕️",
  },
  {
    title: "⛩ 深度遊交友活動 ⛩",
    location: "不定",
    participants: "4男4女",
    isHot: false,
    isRecommended: false,
    // externalLink: "https://forms.gle/jFwXNVPLRUGArKwd9",
  },

  {
    title: "🪔 韓式療癒製香班 🪔",
    // externalLink: "https://forms.gle/MYwQyTzQW5WtjpL76",
  },
  {
    title: "🥊 泰拳交友班 🥊",
  },
  {
    title: "🌸 韓式花藝交友班 🌸",
  },
  {
    title: "💃🏼𝟑𝟎人 踩格仔交友Party🕺🏻",
  },
  {
    title: "🍕𝟐𝟎人Pizza 製作派對 [一齊製作和享用🍴]",
  },
  // {
  //   title: "🍍 台式鳯梨酥製作班 🍍",
  // },
];

export const ActivityPage = () => {
  return (
    <>
      <Stack
        spacing={2}
        alignItems="center"
        pt={5}
        sx={{ margin: { xs: "0 20px", md: "0 30px" } }}
      >
        <Stack
          mb={2}
          spacing={2}
          alignItems="center"
          maxWidth="600px"
          // maxWidth={{ sm: "500px", md: "700px" }}
        >
          <Box component="img" src={HcIcon} sx={{ width: "25%" }} />
          <Box component="img" src={TextImg} sx={{ width: "65%" }} />
          {/* <Typography
            variant="h4"
            textAlign="center"
            sx={{ width: "100%", color: "#434343" }}
          >
            報名參加單身活動
          </Typography> */}
          {activities.map((activity, index) => {
            // if (index === 2) {
            //   return (
            //     <>
            //       <RegisterNow />
            //       <Activity activity={activity} />
            //     </>
            //   );
            // }
            const act = activity;
            act.externalLink = `${linkPrefix}${activity.title}。`;

            return <Activity activity={act} />;
          })}
        </Stack>
      </Stack>
    </>
  );
};
